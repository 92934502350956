import { Component, OnInit, OnDestroy } from '@angular/core';
import { ViewPropertiesService } from '../core/service/view-properties.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import {HttpResponse} from "@angular/common/http";

@Component({
	// tslint:disable-next-line:component-selector
	selector: 'weblocation-file-download',
	templateUrl: './weblocation-file-download.component.html',
	styleUrls: ['./weblocation-file-download.component.css']
})
export class WeblocationFileDownloadComponent implements OnInit, OnDestroy {
	fileName: string;
	itemId: string;
	error: any;
	private subscriptions: Subscription = new Subscription();

	constructor(private viewPropertiesServices: ViewPropertiesService, private route: ActivatedRoute, private router: Router) { }

	ngOnInit() {
		this.route.paramMap.subscribe(params => {

			this.itemId = params.get('itemId');
			this.fileName = params.get('fileName');
			console.log('itemId is ' + this.itemId + '&' + 'fileName is ' + this.fileName);

			this.viewPropertiesServices.filedownloadWebLocation(this.itemId, this.fileName).subscribe(
				(response: HttpResponse<any>) => {
					const downloadUrl =  JSON.parse(response.body).url;
					console.log('GCP Presigned URL :' + downloadUrl);
					if (downloadUrl) {
						this.downloadFileFromUrl(downloadUrl);
						this.router.navigate(['/apdm/v4.0/by-id/item/', this.itemId]);
					} else {
						console.error('Error while downloading file');
					}

				});


		});
	}

	ngOnDestroy() {
		this.subscriptions.unsubscribe();
	}

	private downloadFileFromUrl(url: string) {
		// Create an anchor element and programmatically click it to trigger the download
		const a = document.createElement('a');
		a.href = url;
		document.body.appendChild(a);
		a.click();
		document.body.removeChild(a);
	}
}
