import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { environment } from "../../../environments/environment";
import { ViewProperties } from '../model/viewProperties';
import { ChildRequest } from '../model/viewPropertiesModels';
import { Observable, BehaviorSubject } from 'rxjs';
import {map} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class ViewPropertiesService {

  private teamURI = environment.apdmServiceUrl;
  private webDavURI = environment.apdmWebDavUrl;
  private headers: HttpHeaders;
  bSubject = new BehaviorSubject("a");

  constructor(private http: HttpClient) { }
  getCpscSystem(data: ViewProperties) {
    return this.http.post(this.teamURI + 'viewdomain', data);

  }
  viewPrefixLoad(data: ViewProperties) {
    console.log('view Prefix');
    return this.http.post(this.teamURI + 'viewPrefix', data);
  }
  viewBasePart(data: ViewProperties) {
    console.log('viewBasePart');
    return this.http.post(this.teamURI + 'viewBasePart', data);
  }
  viewEngineProgram(data: ViewProperties) {
    return this.http.post(this.teamURI + 'viewengine', data);
  }
  viewTransmission(data: ViewProperties) {
    return this.http.post(this.teamURI + 'viewtransmission', data);
  }
  viewPerson(data: ViewProperties) {
    return this.http.post(this.teamURI + 'checktype', data);
  }
  viewTeam(data: any) {
    return this.http.post(this.teamURI + 'checktype', data);
  }
  viewCheckType(data:any){
    return this.http.post(this.teamURI + 'checktype', data);
  }
  viewPart(data:ViewProperties){
    return this.http.post(this.teamURI + 'viewpart', data);
  }
  viewVehicleProgram(data:ViewProperties){
    return this.http.post(this.teamURI + 'viewvehicle', data);
  }
  viewEngineBuild(data:ViewProperties){
    return this.http.post(this.teamURI + 'viewenginebuild', data);
  }
  viewProject(data:ViewProperties){
    return this.http.post(this.teamURI + 'viewproject', data);
  }
  viewFolder(data:ViewProperties){
    return this.http.post(this.teamURI + 'viewfolder', data);
  }
  viewWebRef(data:ViewProperties){
    return this.http.post(this.teamURI + 'viewwebref', data);
  }
  viewSDRC(data:ViewProperties){
    return this.http.post(this.teamURI + 'viewsdrc', data);
  }
  viewDrawing(data:ViewProperties){
    return this.http.post(this.teamURI + 'viewdrawing', data);
  }
  viewSave(data:ViewProperties){
    return this.http.post(this.teamURI + 'viewsaved', data);
  }
  viewOptegra(data:ViewProperties){
    return this.http.post(this.teamURI + 'viewoptegrafolder', data);
  }
  viewPTNVHQOSTest(data:ViewProperties){
    console.log('TEST......');
    return this.http.post(this.teamURI + 'viewtestdatafolder', data);
  }
  viewPTNVHQOSReport(data:ViewProperties){
    console.log(data);
    return this.http.post(this.teamURI + 'viewreportdatafolder', data);
  }
  viewTestLab(data:ViewProperties){
    return this.http.post(this.teamURI + 'viewtestlab', data);
  }
  viewFile(data:ViewProperties){
    return this.http.post(this.teamURI + 'viewfile', data);
  }
  openTitle(data:ViewProperties){
    return this.http.post(this.teamURI + 'opentitle', data);
  }
  viewTree(data:any){
    console.log('view Tree View',data);
    return this.http.post(this.teamURI + 'parent', data);
  }
  viewDataTable(data:ChildRequest[]){
    console.log('view Data Table',data);
    return this.http.post(this.teamURI + 'dataview', data,{
      headers: this.headers,
      });
  }
  webLocation(data:string){
    return this.http.get(data, {
      headers: this.headers,
      observe: 'response',
      responseType: 'blob'
    });

  }
  getType(itemId: number) {
    return this.http.get(this.teamURI + 'gettypelocation/'+ itemId);
  }
  viewTotalStorage(data:ViewProperties){
        return this.http.post(this.teamURI + 'totalstorage', data);
  }
  versionDownload(data: string): Observable<HttpResponse<Blob>> {
    console.log("versionDownload",data)
    return this.http.get(this.teamURI + 'by-id/item/'+ data, {
      headers: this.headers,
      observe: 'response',
      responseType: 'blob',
    },);
  }

  AccessRequired(data: any): Observable<HttpResponse<Blob>> {
    console.log("versionDownload",data)
    return this.http.get(this.webDavURI + 'by-id/item/'+ data, {
      headers: this.headers,
      observe: 'response',
      responseType: 'blob',
    },);
  }


	getDataforDownStream(data: any): Observable<HttpResponse<any>> {
		console.log("versionDownload",data)
		return this.http.get(this.webDavURI + 'viewprophtml/by-id/item/'+ data, {
			headers: this.headers,
			observe: 'response',
			responseType: 'text',
		},);
	}

	AccessRequiredNew(data: any): Observable<HttpResponse<any>> {
		console.log("versionDownload", data);
		const url = this.webDavURI + 'by-id/item/' + data;
		console.log("Constructed URL: ", url);
		console.log("filecheckout..");
		return this.http.get(url, {
			headers: this.headers,
			observe: 'response',
			responseType: 'text' // Change to 'text' to handle both JSON and HTML
		}).pipe(
			map(response => {
				const contentType = response.headers.get('Content-Type');
				if (contentType && contentType.indexOf('application/json') !== -1) {
					// If the response is JSON, parse it
					response = response.clone({ body: JSON.parse(response.body) });
				}
				// If the response is HTML or any other type, handle accordingly
				return response;
			})
		);
	}


	getDataforDownStreamforParent(data: any): Observable<HttpResponse<any>> {
		return this.http.get(this.webDavURI +'by-id/item/'+ data, {
			headers: this.headers,
			observe: 'response',
			responseType: 'text',
		}, );
	}

	filedownloadWebLocation(itemId: any, fileName): Observable<HttpResponse<any>> {
		console.log('filedownloadWebLocation Service running..');
		const url = this.webDavURI + 'by-id/item/' + itemId + '/' + fileName;
		console.log('Constructed URL: ', url);
		console.log('filecheckout..');
		return this.http.get(url, {
			headers: this.headers,
			observe: 'response',
			responseType: 'text',
		}, );

	}



  getModalYear():Observable<HttpResponse<string>>{
    return this.http.get(this.teamURI + 'getyear',{
      headers: this.headers,
      observe: 'response',
      responseType: 'text',
    });
  }

  addFilename(filename: string) {
    this.bSubject.next(filename);
  }
}
